import { getEnv } from "../utils";
import type { Slice } from "./types";

const BASE_URL =
  typeof window === "undefined"
    ? getEnv("PRODUCT_API")
    : `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/api`;

const VALUES_DENYLIST: (string | number | boolean | number[] | string[])[] = [
  "prijsdalers",
];

interface Resolver {
  productToken: string;
  setProductToken: (token: string) => void;
  list: (variables: {
    [key: string]: string | number | number[] | boolean | string[] | undefined;
    slug?: string;
  }) => Promise<Slice[]>;
}

export const resolver: Resolver = {
  productToken: getEnv("PRODUCT_API_TOKEN"),
  setProductToken(token) {
    this.productToken = token;
  },
  async list(variables = {}) {
    const url = new URL(`${BASE_URL}/slices`);
    const headers = new Headers({
      Accept: "application/json",
      "Content-Type": "application/json",
    });

    if (this.productToken) headers.set("authorization", this.productToken);

    const filter = { ...variables };

    Object.keys(filter).forEach((key) => {
      const value = filter[key];

      if (value === undefined || value === "") return;
      if (VALUES_DENYLIST.includes(value)) return;

      if (Array.isArray(value)) {
        if (value.filter((v) => v === "").length > 0) return;

        value.forEach((val) => url.searchParams.append(key, val.toString()));
      } else if (value) {
        if (value === "") return;

        url.searchParams.append(key, value.toString());
      }
    });

    return fetch(url, {
      headers: { authorization: getEnv("PRODUCT_API_TOKEN") },
      cache: "no-store",
    })
      .then(async (res) =>
        res.status === 200
          ? ((await res.json()) as Promise<Slice[]>)
          : ([] as Slice[])
      )
      .catch((e) => [] as Slice[]);
  },
};
